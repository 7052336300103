import { useStaffStore } from "~~/stores/Staff/StaffStore"

export default defineNuxtRouteMiddleware(async (to, from) => {
  const context = useNuxtApp();
  const store = useStaffStore(context.$pinia)
  try {
    await store.loadState()
  
    if (store.info === null) {
      return navigateTo('/login')
    }
  } catch (e) {
    return navigateTo('/login')
  }
})